import dynamic from "next/dynamic";
import { Article } from "../Loading/StoriesLoading";
import { StoryTitle } from "../../domain/stories";
import { useActiveTab } from "../../application/home";
const Story = dynamic(() => import("../Story"), {
  ssr: false,
  loading: () => <Article showTitle={true}></Article>,
});

type LazyStoryProps = {
  articles: any[];
  title: StoryTitle;
};
const LazyStory: React.FC<LazyStoryProps> = (props) => {
  const { articles, title } = props;
  const activeTab = useActiveTab();
  if (articles.length === 0) {
    return <Article showTitle={true}></Article>;
  }

  return (
    <div className="mt-6" key={activeTab}>
      <Story
        key="related"
        title={title}
        articleList={articles}
        hideHeader={false}
        activeTab={activeTab}
      />
    </div>
  );
};

export default LazyStory;
