import React, {
  FC,
  ReactNode,
  ReactElement,
  ComponentProps,
  useRef,
} from "react";
import classNames from "classnames";
import { useSpring, animated } from "@react-spring/web";
import usePropsValue from "utils/hooks/usePropsValue";
// import bound from "utils/bound";
import useIsomorphicLayoutEffect from "utils/hooks/useIsomorphicLayoutEffect";
import usePortrait from "utils/hooks/usePortrait";

export type TabProps = {
  title: string | ReactNode;
  disabled?: boolean;
  forceRender?: boolean;
  destroyOnClose?: boolean;
};
export const Tab: FC<TabProps> = () => {
  return null;
};

export type TabsProps = {
  activeKey?: string | null;
  defaultActiveKey?: string | null;
  activeLineMode?: "auto" | "full" | "fixed";
  stretch?: boolean;
  onChange?: (key: any) => void;
  className?: string;
  children?: ReactNode;
  activeLineWidth?: string;
  borderBottom?: boolean;
};

export const Tabs: FC<TabsProps> = (props) => {
  const tabListContainerRef = useRef<HTMLDivElement>(null);
  const activeLineRef = useRef<HTMLDivElement>(null);
  const keyToIndexRecord: Record<string, number> = {};
  const fistRender = useRef(true);
  let firstActiveKey: string | null = null;

  const panels: ReactElement<ComponentProps<typeof Tab>>[] = [];

  React.Children.forEach(props.children, (child, index) => {
    if (!React.isValidElement(child)) return;
    const key = child.key;
    if (typeof key !== "string") return;
    if (index === 0) {
      firstActiveKey = key;
    }
    const length = panels.push(child);
    keyToIndexRecord[key] = length - 1;
  });

  const [activeKey, setActiveKey] = usePropsValue({
    value: props.activeKey,
    defaultValue: props.defaultActiveKey ?? firstActiveKey,
    onChange: (v) => {
      if (v === null) return;
      props.onChange?.(v);
    },
  });

  const [{ x, width }, api] = useSpring(() => ({
    x: 0,
    width: 0,
    config: {
      tension: 300,
      clamp: true,
    },
  }));

  const [{ scrollLeft }, scrollApi] = useSpring(() => ({
    scrollLeft: 0,
    config: {
      tension: 300,
      clamp: true,
    },
  }));
  //  add a opacity mask at the left and right side
  const [{ leftMaskOpacity, rightMaskOpacity }, maskApi] = useSpring(() => ({
    leftMaskOpacity: 0,
    rightMaskOpacity: 0,
    config: {
      clamp: true,
    },
  }));

  function animate(immediate = false) {
    const container = tabListContainerRef.current;
    if (!container) return;
    const activeIndex = keyToIndexRecord[activeKey as string];
    if (activeIndex === undefined) {
      api.start({
        x: 0,
        width: 0,
        immediate: true,
      });
      return;
    }
    const activeLine = activeLineRef.current;
    if (!activeLine) return;

    const activeTabWrapper = container.children.item(
      activeIndex + 1
    ) as HTMLDivElement;
    const activeTab = activeTabWrapper.children.item(0) as HTMLDivElement;
    const activeTabLeft = activeTab.offsetLeft;
    const activeTabWidth = activeTab.offsetWidth;
    const activeTabWrapperLeft = activeTabWrapper.offsetLeft;
    const activeTabWrapperWidth = activeTabWrapper.offsetWidth;

    const containerWidth = container.offsetWidth;
    const containerScrollWidth = container.scrollWidth;
    const containerScrollLeft = container.scrollLeft;

    const activeLineWidth = activeLine.offsetWidth;

    let x = 0;
    let width = 0;
    if (props.activeLineMode === "auto") {
      x = activeTabLeft;
      width = activeTabWidth;
    } else if (props.activeLineMode === "full") {
      x = activeTabWrapperLeft;
      width = activeTabWrapperWidth;
    } else {
      x = activeTabLeft + (activeTabWidth - activeLineWidth) / 2;
    }
    api.start({
      x,
      width,
      immediate,
    });

    const maxScrollDistance = containerScrollWidth - containerWidth;
    if (maxScrollDistance <= 0) return;
    // console.log("maxScrollDistance", containerScrollWidth, containerWidth);
    // const nextScrollLeft = bound(
    //   activeTabLeft - (containerWidth - activeTabWidth) / 2,
    //   0,
    //   containerScrollWidth - containerWidth
    // );

    // scrollApi.start({
    //   scrollLeft: nextScrollLeft,
    //   from: { scrollLeft: containerScrollLeft },
    //   immediate,
    // });
  }
  useIsomorphicLayoutEffect(() => {
    if (fistRender.current) {
      fistRender.current = false;
      animate(true);
      return;
    }
    animate();
  }, [activeKey]);

  usePortrait(animate, false);

  const { className, borderBottom } = props;

  return (
    <div className={classNames("tabs", className)}>
      <div
        className={classNames("tabs-header relative", {
          "border-b border-gray-800": borderBottom,
        })}
      >
        <animated.div
          className={classNames(`header-mask`, `header-mask-left`)}
          style={{
            opacity: leftMaskOpacity,
          }}
        />
        <animated.div
          className={classNames(`header-mask`, `header-mask-right`)}
          style={{
            opacity: rightMaskOpacity,
          }}
        />
        <animated.div
          className="tabs-list flex flex-nowrap justify-start items-center relative overflow-x-scroll scrollbar-hide"
          ref={tabListContainerRef}
          scrollLeft={scrollLeft}
        >
          <animated.div
            ref={activeLineRef}
            className="tab-line h-1 w-5 bg-blue-400 absolute bottom-0 rounded-lg"
            style={{
              width: props.activeLineMode === "fixed" ? "20px" : "20px",
              x,
            }}
          />

          {panels.map((panel, index) => {
            const isActive = panel.key === activeKey;
            const c = isActive ? "text-white" : "text-gray-500";
            const cls = classNames(
              "tabs-tab pt-2 pb-2 whitespace-nowrap m-auto relative w-min font-bold",
              c
            );
            return (
              <div className="tabs-tab-wrapper px-1.5" key={panel.key}>
                <div
                  className={cls}
                  data-pixel-click
                  data-pixel-id="clickSwitchTab"
                  data-pixel-type={panel.props.title}
                  onClick={() => {
                    const { key } = panel;
                    if (panel.props.disabled) return;
                    if (key === undefined || key === null) {
                      return;
                    }
                    setActiveKey(key.toString());
                  }}
                >
                  {panel.props.title}
                </div>
              </div>
            );
          })}
        </animated.div>
      </div>
    </div>
  );
};
