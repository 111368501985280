import c from "classnames";

export const Avatar: React.FC<any> = (props) => {
  const cls = c("rounded-full bg-grey-600", "h-20 w-20", props.className);
  return <div className={cls}></div>;
};
export const Shape: React.FC<any> = (props) => {
  const cls = c("bg-grey-600", props.className);
  return <div className={cls}></div>;
};
export const Block: React.FC<any> = (props) => {
  const cls = c("rounded-lg bg-grey-600", "w-full", props.className);
  return <div className={cls}></div>;
};
export const Title: React.FC<any> = (props) => {
  const cls = c("h-6 bg-grey-600 rounded", props.className);
  return <div className={cls}></div>;
};

export const Paragraph: React.FC<any> = (props) => {
  const { widths = ["w-[100%]"], wrapperClassName, itemClassName } = props;
  const wCls = c("space-y-3", wrapperClassName);
  return (
    <div className={wCls}>
      {widths.map((value: any, i: number) => {
        const pCls = c(
          "h-3 bg-grey-600 rounded",
          `${value}`,
          props.itemClassName
        );
        return <div className={pCls} key={i}></div>;
      })}
    </div>
  );
};

const Skeleton = () => {
  return (
    <>
      <div className="p-4 max-w-sm w-full mx-auto">
        <div className="animate-pulse flex space-x-4">
          <Avatar />
          <Title />
        </div>
      </div>
      <Block />
      <Paragraph />
    </>
  );
};

export default Skeleton;
