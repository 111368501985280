import Head from "next/head";
import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import Home from "../ui/HomePage";
const title = "What to Watch";
import { fetchHomeData } from "../services/homeAdapter";
import { HomeData } from "../application/port";
import { TabsEnum, setActiveTab } from "../domain/tabs";
import { getValue } from "../utils/getValue";
// This gets called on every request
import { Provider } from "jotai";

export const getServerSideProps: GetServerSideProps<{
  data: HomeData;
  initialTab: TabsEnum;
  time: number;
  spend: number;
}> = async (context) => {
  const { res, req, query } = context;
  const queryTab = query.tab as TabsEnum;
  const tab = setActiveTab(queryTab) as TabsEnum;

  const time1 = Date.now();
  // Fetch data from external API
  const data = await fetchHomeData({ tab: tab, endIndex: 1 });
  // console.log("getServerSideProps", data);
  // Pass data to the page via props
  const spend = Date.now() - time1;
  try {
    console.log("request timestamp:", time1, "spend", spend);
  } catch (error) {
    console.log(error);
  }
  const top_trend = getValue(data, ["top_trend", "data", "data"], []);
  const hasError = top_trend.length <= 5;

  if (hasError) {
    res.setHeader(
      "Cache-Control",
      "public, max-age=10, stale-while-revalidate=9"
    );
  } else {
    // Pass data to the page via props
    res.setHeader(
      "Cache-Control",
      "public, max-age=1800, stale-while-revalidate=59"
    );
  }

  return { props: { data, initialTab: tab, time: Date.now(), spend } };
};

const HomePage = ({
  data,
  initialTab,
  time,
  spend,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <Provider>
        <Home initialProps={data} title={title} initialTab={initialTab} />
        <span className="hidden">
          {time}--{spend}
        </span>
      </Provider>
    </>
  );
};

export default HomePage;
