import { Title, Block, Paragraph } from "./Skeleton";

export const LargeCardsSkeleton = () => {
  const data = [1, 2, 3];
  return (
    <div className="mt-6 loading">
      <Title className="ml-4 mb-4 w-[40%] mt-2" />

      <div className="overflow-hidden flex">
        {data.map((item, index) => {
          return (
            <div className="ml-4 w-[12.5625rem] grow-0 shrink-0" key={index}>
              <Block className="mb-4 h-[18.8125rem] w-full" />
              {/* <Title className="mb-2 w-[60%]" /> */}
              {/* <Block className="mb-4 h-[2.625rem] w-full" /> */}
              {/* <Paragraph wrapperClassName="mr-4 mb-3" widths={["w-[70%]"]} /> */}
              <Block className="mb-4 h-[1.5rem] w-full" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const SmallCardsSkeleton = () => {
  const data = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <div className="mt-6 loading">
      <Title className="ml-4 mb-4 w-[40%]" />

      <div className="overflow-hidden flex">
        {data.map((item, index) => {
          return (
            <div className="ml-4 w-[7.5rem] grow-0 shrink-0" key={index}>
              <Block className="mb-4 w-full h-[11.25rem]" />
            </div>
          );
        })}
      </div>
    </div>
  );
};
