export const capitalizeFirstChar = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const removeBlank = (words: string) => {
  if (typeof words === "string" && typeof words.replaceAll === "function") {
    return words.replaceAll(" ", "");
  }
  return words + "";
};
