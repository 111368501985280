import Image from "../Image";
import classnames from "classnames";
import InTheater from "./InTheater";

type ServiceProviderProps = {
  className?: string;
  max: number;
  min: number;
  providers: Array<any>;
  inTheater: boolean;
  imgShadow?: boolean;
  bgClass?: string;
};
const ServiceProvider: React.FC<ServiceProviderProps> = (props) => {
  const c = classnames(
    "service flex justify-start space-x-2 items-center",
    props.className
  );
  const { inTheater, providers = [], max, min, imgShadow = true } = props;
  const len = providers.length;
  const largerThanMax = len > max;
  const largerThanMin = len > min;
  let p = [];
  let extra = ``;
  if (inTheater) {
    p = largerThanMin ? providers.slice(0, min - 1) : providers;

    extra = largerThanMin ? `+${len - p.length}` : "";
  } else {
    p = providers.slice(0, largerThanMax ? max - 1 : len);
    extra = largerThanMax ? `+${len - p.length}` : "";
  }
  const c1 = classnames(
    "rounded-full w-6 h-6 overflow-hidden relative shrink-0",
    imgShadow ? "img-box-shadow" : ""
  );
  const imgC = classnames(
    "w-full h-full relative",
    props.bgClass ? props.bgClass : "bg-grey-600"
  );
  return (
    <div className={c}>
      {inTheater && <InTheater />}
      {p.map((provider, index) => {
        return (
          <div className={c1} key={index}>
            <Image
              alt=""
              src={provider.icon}
              layout="fill"
              className={imgC}
              quality={100}
              unoptimized={true}
            />
          </div>
        );
      })}
      {extra && <span className="text-sm leading-3.5 bold">{extra}</span>}
    </div>
  );
};

export default ServiceProvider;
