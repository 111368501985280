import React from "react";
import classnames from "classnames";
import SNLink from "../SNLink";

export type CardHeaderProps = {
  title: string;
  desc?: string;
  onClick?: () => void;
  className?: string;
  moreText?: string;
  href?: string;
};
const Header: React.FC<CardHeaderProps> = ({
  title,
  desc,
  onClick,
  className,
  moreText,
  href,
}) => {
  const c = classnames("flex justify-between items-start mr-4", className);
  return (
    <div className={c}>
      <div>
        <h1 className="text-xl text-white-500 font-bold">{title}</h1>
        {desc && (
          <span className="text-xs text-dark-grey leading-6 ">{desc}</span>
        )}
      </div>
      {moreText && href && (
        <div onClick={() => onClick && onClick()}>
          <SNLink
            href={href}
            tag="span"
            className="text-blue-400 leading-6 text-xs font-bold"
          >
            {moreText}
          </SNLink>
        </div>
      )}
    </div>
  );
};

export default Header;
