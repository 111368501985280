export enum StoryTitle {
  related = "Related Stories",
  more = "More Stories",
}

export enum StroyFromEnum {
  default = "",
  ufb = "ufb",
  // all = "all",
  movies = "movies",
  shows = "shows",
}

export enum StoryThemeEnum {
  dark = "dark", // default, used in w2w hub
  light = "light", // used in ufb
}

export enum StoryBackgroundColorEnum {
  white = 'bg-white-008', // default & ufb white mode
  dark = 'bg-dark-910', // ufb dark mode
}