export enum TabsEnum {
  // all = "all",
  movies = "movies",
  shows = "shows",
}

export const UITabsList = Object.values(TabsEnum).map((v) => {
  if (v === TabsEnum.shows) {
    return { label: "TV Shows", value: TabsEnum.shows };
  }
  return {
    label: v.slice(0, 1).toUpperCase() + v.slice(1),
    value: TabsEnum[v],
  };
});

const tabValue = Object.values(TabsEnum);

const TabKeys = Object.keys(TabsEnum).map((key) => key.toLowerCase());
export const convert2TabsValue = (v: string) => {
  const r = v.split(" ");

  if (r.length > 1) {
    return r[1].toLowerCase();
  }
  return v.toLowerCase();
};

export const setActiveTab = (tab: TabsEnum) => {
  if (tabValue.includes(tab)) {
    return tab;
  }
  return tabValue[0];
};
