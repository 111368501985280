import React, { useRef } from "react";
import Header from "../CardHeader/header";
import MovieCard from "./movie-card";
// import MovieDescription from "./movie-description";
import SNLink from "../SNLink";
import { usePixel } from "utils/hooks/usePixel";
import { capitalizeFirstChar } from "utils/stringHelper";
import ServiceProvider from "./ServiceProvider";

export type LargeCellMovieProps = {
  data: Record<string, any>;
  title: string;
  desc?: string;
  type: string;
  listType?: string;
};
const LargeCellMovie: React.FC<LargeCellMovieProps> = ({
  data,
  title,
  desc,
  type,
  listType,
}) => {
  const [track] = usePixel();
  const onClick = () => {
    const pixelId = `clickTrending${capitalizeFirstChar(type)}ViewAll`;
    track("click", { id: pixelId });
  };
  const rootRef = useRef<any>(null);

  return (
    <div className="mt-6">
      <Header
        title={title}
        desc={desc}
        moreText="View All"
        onClick={onClick}
        className="pl-4"
        href={`/list/${type}?name=top_trend`}
      />
      <div
        className="overflow-x-scroll overflow-y-hidden mt-4 pb-4 flex scrollbar-hide snap-mandatory snap-x"
        ref={rootRef}
      >
        {data.map((item: any, index: number) => {
          return (
            <SNLink
              href={`/detail/${type}/${item.id}?${listType}_index=${index}`}
              key={index}
              className="mr-4 grow-0 shrink-0 w-[12.5625rem] relative first:ml-4 snap-center"
              data-pixel-ctr
              data-pixel-id="trendingItem"
              data-pixel-type={item.id}
              data-pixel-index={index}
            >
              <MovieCard
                className="w-[12.5625rem] h-[18.8125rem]"
                url={item.poster}
                quality={"50"}
                lazyRoot={rootRef}
                sizes="54vw"
                placeholder={item.title}
                lazyBoundary="400px"
                // priority={index < 3 ? true : false}
              />
              {/* <MovieDescription
                desc={item.description}
                title={item.title}
                imdb={item.imdb}
                providers={item.providers}
                inTheater={item.inTheater}
              /> */}
              <ServiceProvider
                max={6}
                providers={item.providers}
                inTheater={item.inTheater}
                min={2}
                className="service mt-2 flex justify-start space-x-2 text-white-500"
              />
            </SNLink>
          );
        })}
        <div className="w-1 h-4 grow-0 shrink-0"></div>
      </div>
    </div>
  );
};

export default LargeCellMovie;
