import { Dispatch, SetStateAction, useRef, useState } from "react";
import useMemoizedFn from "./useMemoizedFn";
import useUpdate from "./useUpdate";

type Options<T> = {
  value?: T;
  defaultValue: T;
  onChange?: (v: T) => void;
};

function usePropsValue<T>(options: Options<T>) {
  const { value, defaultValue, onChange } = options;

  const update = useUpdate();

  const stateRef = useRef<T>(value !== undefined ? value : defaultValue);
  if (value !== undefined) {
    stateRef.current = value;
  }

  const setState = useMemoizedFn((v: SetStateAction<T>) => {
    const nextValue =
      typeof v === "function"
        ? (v as (prevState: T) => T)(stateRef.current)
        : v;
    if (value === undefined) {
      stateRef.current = nextValue;
      update();
    }
    onChange?.(nextValue);
  });
  return [stateRef.current, setState] as const;
}
export default usePropsValue;
