import { IMovie } from "../domain/movie";
import { getValue } from "../utils/getValue";
import { providerAdapter } from "../services/fieldAdapter";

export const formatCardResponse = (json: Record<string, any>) => {
  const { entries = [], ...rest } = json;

  const data = entries.map((entry: Record<string, any>) => {
    const item: IMovie = {
      id: entry.id.split("/").join("_"),
      type: entry.type,
      title: entry.title || "",
      description: entry.description,
      imdb: entry.imdbScore,
      poster: getValue(entry, ["summaryImage", "url"], ""),
      providers: providerAdapter(entry),
      // inTheater: entry.inTheater || false,
    };

    return item;
  });
  const res = { ...rest, data };
  return res;
};
