function baseGet(object: Record<string, any>, path: string | any[]) {
  let index = 0;
  const length = path.length;

  while (object != null && index < length) {
    object = object[path[index++]];
  }
  return index && index == length ? object : undefined;
}
export const getValue = (
  object: Record<string, any>,
  path: string | any[],
  defaultValue?: any
) => {
  path = typeof path === "string" ? path.split(".") : path;
  const result = object === null ? undefined : baseGet(object, path);
  return (result === undefined ? defaultValue : result) as any;
};
