import { useState, useRef, useEffect } from "react";
import cls from "classnames";
import Tabs from "../Tabs";
import { UITabsList, TabsEnum } from "../../domain/tabs";
import { StoryTitle } from "../../domain/stories";
import { HomeData } from "../../application/port";
import { useFetchDataByTab } from "../../application/home";
import { useFetchStory } from "../../application/story";
import { LargeCardsSkeleton } from "../Loading/HomeLoading";
import NavBar from "../NavBar";
import LargeCellMovie from "../Movie/large-cell-movie";
import LazyMovie from "./LazyMovie";
import LazyStory from "./LazyStory";
import { usePixel } from "utils/hooks/usePixel";
import { shuffle } from "utils/arrayHelper";

const Home: React.FC<{
  initialProps: HomeData;
  title: string;
  initialTab: TabsEnum;
}> = (props) => {
  const { initialProps, title, initialTab } = props;

  const [activeTab, uiData, ui, fetchData] = useFetchDataByTab(
    initialProps,
    initialTab
  );
  const [moreStory, relatedStory] = useFetchStory(true);
  const related_articles = shuffle(relatedStory).slice(0, 3);
  let more_articles = shuffle(moreStory).slice(0, 10);
  // in case more_articles is empty, display some from related.
  if (!more_articles.length) {
    more_articles = shuffle(relatedStory).slice(0, 10);
  }

  const { top_trend, coming_soon, new_release, discover_more } = uiData;

  const trendingTitle =
    activeTab === TabsEnum.movies ? "Trending Movies" : "Trending Shows";

  const [track] = usePixel();
  useEffect(() => {
    let timer = setTimeout(() => {
      track("pageView", {
        id: "openWhatToWatchPage",
      });
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  // for Phase2
  const [visible, setVisible] = useState(false);
  const containerRef = useRef<HTMLElement>(null);

  const handleFilter = () => {
    console.log("handleFilter", window.location);
    setVisible((isVisible) => !isVisible);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onTabChange = (tab: TabsEnum) => {
    fetchData(tab);
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  };

  const scrollableCls = cls("main");

  return (
    <div className="page">
      <NavBar className="bg-dark-700" title={title} backArrow={true}></NavBar>

      <div className="pl-4 flex items-center border-b border-gray-800 z-20 w-full bg-dark-700 top-0">
        <div className="flex-1 flex justify-center w-full overflow-hidden">
          <Tabs
            className="overflow-hidden"
            onChange={onTabChange}
            defaultActiveKey={activeTab}
          >
            {UITabsList.map((tab, index) => {
              return <Tabs.Tab title={tab.label} key={tab.value}></Tabs.Tab>;
            })}
          </Tabs>
        </div>
        {/* <SvgIcon
          url="./icons/filter.svg"
          bgColor="#fff"
          className="mt-2 mr-4 mb-2 ml-4 h-6 w-6"
          onClick={handleFilter}
        /> */}
      </div>
      {/* activeTab !== TabsEnum.movies && */}
      <main className={scrollableCls} ref={containerRef}>
        <div key={top_trend.length}>
          {top_trend.length === 0 && <LargeCardsSkeleton key="1" />}
        </div>

        {top_trend.length > 0 && (
          <LargeCellMovie
            title={trendingTitle}
            data={top_trend}
            // desc="Information gathered from IMDB"
            type={activeTab}
            key={activeTab + "trending"}
            listType="trending"
          />
        )}

        <LazyStory
          key="related"
          title={StoryTitle.related}
          articles={related_articles}
        />
        <LazyMovie
          title="Releases This Week"
          data={new_release}
          type={activeTab}
          listType="new_release"
          key={activeTab + "release"}
        />

        <LazyMovie
          title="Coming Soon"
          data={coming_soon}
          type={activeTab}
          listType="coming_soon"
          key={activeTab + "coming"}
        />
        <LazyMovie
          title="Discover More"
          data={discover_more}
          type={activeTab}
          listType="discover_more"
          key={activeTab + "discover"}
        />
        {more_articles.length && (
          <LazyStory
            key="more"
            title={StoryTitle.more}
            articles={more_articles}
          />
        )}
        {/* <Popup
          visible={visible}
          bodyStyle={{ height: "80vh", overflowY: "hidden", maxHeight: "80vh" }}
          bodyClassName="rounded-t-3xl max-w-3xl"
          onMaskClick={onClose}
          onClose={onClose}
          title="Filter & Sort"
        >
          <PopupContent onClose={onClose} />
        </Popup> */}
        {/* <div
          className="bg-pink-500 active:bg-pink-600 text-white flex items-center justify-center space-x-2 rounded-full shadow-lg cursor-pointer overflow-hidden select-none h-12 duration-100 w-12 fixed left-1/4 top-1/4 transform -translate-x-1/2 -translate-y-1/2 z-20"
          onClick={() => {
            location.reload();
          }}
        >
          🤖
        </div> */}
      </main>
    </div>
  );
};
export default Home;
