import { useEffect, useState, useRef } from "react";
import { fetchStoryData } from "../services/storyAdapter";

export function useFetchStory(lazy?: boolean) {
  const [moreStory, setMoreStory] = useState([]);
  const [relatedStory, setRelatedStory] = useState([]);
  let timer = useRef<any>(null);
  useEffect(() => {
    const getData = async () => {
      const resp = await fetchStoryData();
      const moreStory =
        resp[0] && Array.isArray(resp[0].data) ? resp[0].data : [];
      const relatedStory =
        resp[1] && Array.isArray(resp[1].data) ? resp[1].data : [];
      setMoreStory(moreStory);
      setRelatedStory(relatedStory);
    };
    if (lazy) {
      timer.current = setTimeout(() => {
        getData();
      }, 500);
    } else {
      getData();
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return [moreStory ,relatedStory];
}
