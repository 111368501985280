import { atom } from "jotai";
import { setActiveTab, TabsEnum } from "../domain/tabs";
import { IHomeStore } from "../application/port";
export const tabAtom = atom(TabsEnum.movies);

const homeStore: IHomeStore = {};
export const uiAtom = atom({ loading: false, error: false });
export const activeTabAtom = atom(
  (get) => get(tabAtom),
  (get, set, _arg: TabsEnum) => set(tabAtom, setActiveTab(_arg))
);

export const homeAtom = atom(homeStore, (get, set, payload: any) => {
  const pre = get(homeAtom);
  const { data, tab } = payload;
  const nextValue = { ...pre, [tab]: { ...pre[tab], ...data } };
  set(homeAtom, nextValue);
});
