import { useAtom } from "jotai";
import { useHydrateAtoms } from "jotai/utils";
import { homeAtom, activeTabAtom, uiAtom, tabAtom } from "../services/store";
import { TabsEnum } from "../domain/tabs";
import { useCallback, useEffect } from "react";
import { fetchHomeData } from "../services/homeAdapter";
import { HomeData, IHomeStore } from "../application/port";
import { getValue } from "../utils/getValue";

type HookRes = [TabsEnum, Record<string, any>, any, (tab: TabsEnum) => void];

const getDataByType = (homeData: IHomeStore, activeTab: TabsEnum) => {
  const top_trend = getValue(
    homeData,
    [activeTab, "top_trend", "data", "data"],
    []
  );
  const new_release = getValue(
    homeData,
    [activeTab, "new_release", "data", "data"],
    []
  );
  const coming_soon = getValue(
    homeData,
    [activeTab, "coming_soon", "data", "data"],
    []
  );
  const discover_more = getValue(
    homeData,
    [activeTab, "discover_more", "data", "data"],
    []
  );
  return { top_trend, new_release, coming_soon, discover_more };
};

export function useFetchDataByTab(
  data: HomeData,
  initialTab: TabsEnum
): HookRes {
  const initialData = { [initialTab]: data };
  useHydrateAtoms([
    [homeAtom, { ...initialData }],
    [tabAtom, initialTab],
  ] as const);

  const [activeTab, setActiveTab] = useAtom(activeTabAtom);

  const [homeData, setHomeData] = useAtom(homeAtom);
  const [ui, setUI] = useAtom(uiAtom);
  const fetchData = useCallback(
    async (tab: TabsEnum) => {
      setActiveTab(tab);
      if (homeData && !homeData[tab]) {
        setUI({ loading: true, error: false });
        const data = await fetchHomeData({ tab });
        setUI({ loading: false, error: false });
        setHomeData({ data, tab });
      }
    },
    [setActiveTab, homeData, setHomeData, setUI]
  );
  useEffect(() => {
    const getData = async () => {
      setUI({ loading: true, error: false });
      let startIndex = 1;
      // server no data
      const top_trend = getValue(
        initialData[initialTab],
        ["top_trend", "data", "data"],
        []
      );
      if (
        typeof initialData[initialTab] !== "object" ||
        top_trend.length === 0
      ) {
        startIndex = 0;
      }
      const data = await fetchHomeData({ tab: activeTab, startIndex });
      setHomeData({ data, tab: activeTab });
      setUI({ loading: false, error: false });
    };
    getData();
    return () => {};
  }, []);

  const uiData = getDataByType(homeData, activeTab);

  return [activeTab, uiData, ui, fetchData];
}

export const useActiveTab = () => {
  const [activeTab, setActiveTab] = useAtom(activeTabAtom);
  return activeTab;
};
