import { toHourMinutestr } from "../utils/time";
import { getValue } from "../utils/getValue";
export const providerAdapter = (entry: any) => {
  const providers = entry.providers || [];
  return providers.map((item: any) => {
    const option = item.option;
    const web =
      (option.links || []).filter((link: any) => link.platform === "Web")[0] ||
      {};
    const ios =
      (option.links || []).filter((link: any) => link.platform === "IOS")[0] ||
      {};
    const android =
      (option.links || []).filter(
        (link: any) => link.platform === "Android"
      )[0] || {};

    const iosLink = {
      url: ios.url || "",
      fallback: ios.fallback || "",
    };
    const androidLink = {
      url: android.url || "",
      fallback: android.fallback || "",
    };
    return {
      name: item.name,
      offerType: option.offerType || "",
      price: option.price || 0.0,
      quality: option.quality || "HD",
      ios: iosLink,
      android: androidLink,
      web: web.url || "",
      icon: `/icons/${item.name.toLowerCase()}1.png`,
    };
  });
};
export const idAdapter = (id: string) => id.split("/").join("_");
export const linkIdAdapter = (id: string) => id.split("_").join("/");
export const runTimeAdapter = (runtime: number) => toHourMinutestr(runtime);
export const ratedAdapter = (rated: any) => (rated || []).join(" ");

export const infoAdapter = (json: any) => {
  const infos = [
    { key: "Release Date", value: json.releaseDate || "" },
    // { key: "Director", value: "Martin" },
    { key: "Runtime", value: runTimeAdapter(json.runtime) },
    // { key: "Seasons", value: "4" },
    // { key: "Region of Origin", value: "United States" },
    { key: "Original Audio", value: json.originalAudio || "" },
    { key: "Rated", value: ratedAdapter(json.rated) },
  ];
  if (Array.isArray(json.casts)) {
    const directors = json.casts.filter((cast: any) => cast.job === "Director");
    if (directors.length > 0) {
      let d = {
        key: "Director",
        value: directors.map((d: any) => d.name).join(", ") || "",
      };
      infos.splice(1, 0, d);
    }
  }
  return infos.filter((info) => !!info.value);
};

export const castAdapter = (casts: any[]) => {
  return casts.map((cast: any) => {
    return {
      id: cast.id,
      name: cast.name,
      job: cast.job,
      avatar: cast.profilePhoto ? cast.profilePhoto.url : "",
    };
  });
};

export const allTrailersAdapter = (allTrailers: any[]) => {
  return allTrailers.map((trailer: any) => {
    const p = (trailer.screenCapture || []).filter(
      (s: any) => s.aspect === "16x9"
    )[0];
    return {
      title: trailer.title,
      url: trailer.url,
      poster: p ? p.url : "",
    };
  });
};
export const seasonsAdapter = (json: Record<string, any>) => {
  let seasons = Array.isArray(json.seasons) ? json.seasons : [];
  const res = seasons
    .map((s: any) => {
      return {
        value: s.id,
        label: s.title,
        seasonNumber: s.seasonNumber,
        showId: s.showId,
      };
    })
    .sort((a: any, b: any) => Number(a.seasonNumber) - Number(b.seasonNumber));
  const len = res.length;
  let latestSeason = res[len - 1] || {};
  return { seasons: res, latestSeason };
};

export const episodesAdapter = (json: Record<string, any>) => {
  const { episodes = [], ...rest } = json;
  const data = episodes.map((episode: Record<string, any>) => {
    return {
      seasonId: episode.seasonId,
      episodeId: episode.id,
      title: episode.title,
      poster: getValue(episode, ["summaryImage", "url"], ""),
      num: (episode.title || "").includes("Episode")
        ? ""
        : `Episode ${Number(episode.episodeNumber) < 10 ? "0" : ""}${
            episode.episodeNumber
          }`,
    };
  });
  const res = { ...rest, data };
  return res;
};
