export function toHourMinutestr(str) {
  if (!str) {
    return "";
  }
  let hours = Math.floor(str / 60).toString();

  let minutes = (str % 60).toString();

  if (str !== "0" && str !== "" && str !== null) {
    let h = Number(hours) !== 0 ? hours + " hr " : "";
    return h + (minutes.length < 2 ? "0" + minutes : minutes) + " min";
  } else {
    return "";
  }
}
