import { TabsEnum } from "../domain/tabs";
import { HomeData } from "../application/port";
import { formatCardResponse } from "./commonAdapter";
import {
  fetchTrending,
  fetchNewRelease,
  fetchComingSoon,
  ListParamsType,
} from "./APIAdapter";

type P = {
  tab: TabsEnum;
  endIndex?: number;
  startIndex?: number;
};

const fetcherMap = [
  ["top_trend", fetchTrending],
  ["new_release", fetchNewRelease],
  ["coming_soon", fetchComingSoon],
  ["discover_more", fetchTrending],
];

export async function fetchHomeData(config: P): Promise<HomeData> {
  const { tab, startIndex, endIndex } = config;

  const promises = fetcherMap.slice(
    startIndex ? startIndex : 0,
    endIndex ? endIndex : fetcherMap.length
  );
  const fetchers = promises.map((v, i) => {
    const func = v[1] as (p: ListParamsType) => Promise<any>;
    const type: TabsEnum = tab;
    const params: any = {
      type: type,
      offset: v[0] === "discover_more" ? 10 : 0,
      limit: 10,
      formatter: formatCardResponse,
    };
    if (v[0] === "top_trend") {
      params.shuffled = true;
    }
    return func(params);
  });
  const data = await Promise.all(fetchers);

  let res: any = {};
  for (let i = 0; i < promises.length; i++) {
    const key = promises[i][0] as string;
    const value = data[i];
    if (value.success) res[key] = value;
  }
  // @ts-ignore
  return res;
}
