import dynamic from "next/dynamic";
import { SmallCardsSkeleton } from "../Loading/HomeLoading";
import { TabsEnum } from "../../domain/tabs";
const DoubleCellMovie = dynamic(() => import("../Movie/double-cell-movie"), {
  ssr: false,
  loading: () => <SmallCardsSkeleton></SmallCardsSkeleton>,
});
type LazyMovieProps = {
  data: any[];
  type: TabsEnum;
  title: string;
  listType: string;
};

const LazyMovie: React.FC<LazyMovieProps> = (props) => {
  const { data, type, title, listType } = props;
  if (data.length === 0) {
    return <SmallCardsSkeleton key="1"></SmallCardsSkeleton>;
  }
  return (
    <DoubleCellMovie
      title={title}
      data={data}
      type={type}
      listType={listType}
      key="2"
    />
  );
};

export default LazyMovie;
