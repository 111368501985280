import Tag from "./tag";
export type InTheaterProps = {
  className?: string;
};

const InTheater: React.FC<InTheaterProps> = (props) => {
  return (
    <div className={props.className || "theaters"}>
      <Tag
        text={"In Theaters"}
        className="rounded-full border border-[#808080] px-1.5 py-1 text-xs bg-dark-101"
      />
    </div>
  );
};

export default InTheater;
