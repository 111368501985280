import { Title, Block, Paragraph, Shape } from "./Skeleton";

type ArticleProps = {
  showTitle: boolean;
};
export const Article: React.FC<ArticleProps> = (props) => {
  const { showTitle } = props;
  const data = [1, 2, 3];
  return (
    <div className="mt-6 px-4">
      {showTitle && <Title className="mb-4 w-[40%]" />}

      <div className="overflow-hidden">
        {data.map((item, index) => {
          return (
            <div className="flex space-between" key={index}>
              <div className="flex-1">
                <Paragraph
                  wrapperClassName="mr-1 mb-3 space-y-2"
                  widths={["w-[100%]", "w-[80%]", "w-[50%]"]}
                />
              </div>

              <Shape className="ml-2 h-8 w-8 rounded-sm" />
            </div>
          );
        })}
      </div>
    </div>
  );
};
