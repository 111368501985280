import { fetchMoreArticle, fetchRelatedArticle } from "./APIAdapter";

const fetcherMap = [
  ["more_article", fetchMoreArticle],
  ["related_article", fetchRelatedArticle],
];

export async function fetchStoryData(): Promise<any> {
  const fetchers = fetcherMap.map((v, i) => {
    const func = v[1] as () => Promise<any>;
    return func();
  });

  const data = await Promise.all(fetchers);
  return data;
}
